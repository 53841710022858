.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 30px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 30px;
}
.text {
  margin: 10;
}

img {
  width: 100%;
  height: 100%;
  border-radius: 70px 20px;
}

.img_carousel {
  width: 80%;
  height: 40%;
  min-height: 500px;
  border-radius: 70px 20px;
  color: #fff;
  /* line-height: 160px; */
  text-align: center;
  direction: center;
  background: #c0c0c0;
  border: 2px;
  padding: 20px;
  border-radius: 70px 20px;
}
.img_carousel img {
  width: 80%;
  /* max-width: 1400px; */

  margin: auto;
}

input {
  /* border-radius: 8px; */
  /* background-color: red/; */
  border: 1px solid red;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
  border-radius: 21px;
}
.ant-form-item-label > label {
  min-width: 100px;
}
.ant-modal-content {
  border-radius: 10px;
}
.ant-carousel .slick-slide img {
  display: block;
  width: 40%;
}
p {
  margin-top: 0;
  width: 30%;
  text-align: center;
  font-weight: bold;
  margin-left: 35%;
  font-size: 30px;
  margin-top: 0%;
  /* border: 1px solid springgreen; */
  font-family: system-ui;
}
.anticon svg {
  display: inline-block;
  /* color: black; */
  /* font-size: 50px; */
}
