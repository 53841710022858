.profileHeader{
    display: flex;
    background-color: white;
    justify-content: space-between;
    margin-top: 5px;
    margin-right: 8px;
}
.img{
    margin: 8px;
}

.details{
    display: flex;
}

.bitCoins{
    text-align: right;
}
.avatar{
    margin: auto;
}
.fullName{
    margin-right: 10px;
}